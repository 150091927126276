import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import {
  Routes,
  Route
} from "react-router-dom";

import { Navigate } from 'react-router-dom';
import Marina from '../pages/Marina';
import Region from '../pages/Region';
import './Layout.css';
import RealHome from '../pages/RealHome';
import My404Page from '../pages/My404Page';
import { useTranslation } from 'react-i18next';
import Urls from '../commons/Urls';
import { useLocation } from 'react-router-dom';

export function Layout(props) {
  const { t, i18n } = useTranslation();
  let locationURL = useLocation();
  useEffect(() => {
    let localisationChanged = false;
    let pathname = decodeURI(locationURL.pathname);
    let regionsUrls = Object.keys(Urls.regions);
    regionsUrls.forEach(element => {
      Urls.regions[element].lastIndex = 0;
      let results = Urls.regions[element].exec(pathname);
      if (results && i18n.language !== results.groups.lang) {
        i18n.changeLanguage(results.groups.lang);
        localisationChanged = true;
        return;
      }
    });
    let marinasUrls = Object.keys(Urls.marinas);
    marinasUrls.forEach(element => {
      Urls.marinas[element].lastIndex = 0;
      let results = Urls.marinas[element].exec(pathname);
      if (results && i18n.language !== results.groups.lang) {
        i18n.changeLanguage(results.groups.lang);
        localisationChanged = true;
        return;
      }
    });
    if (!localisationChanged) {
      Urls.home.lastIndex = 0;
      let results = Urls.home.exec(pathname);
      if (results && i18n.language !== results.groups.lang) {
        i18n.changeLanguage(results.groups.lang);
      }
    }
  }, [locationURL]);
  return (
    <div>
      <NavMenu />
      <Routes>
        <Route
          path="/"
          element={<RealHome />}>
        </Route>
        <Route
          path="/:lang"
          element={<RealHome />}>
        </Route>
        <Route
          path="/:lang/:country/:region/:marina"
          element={<Marina />}>
        </Route>
        <Route
          path="/:lang/:country/:region/:marina/:area"
          element={<Marina />}>
        </Route>
        <Route
          path="/:lang/:country/:region/:marina/year/:boatLength/:boatWidth"
          element={<Marina />}
          exact={false}
        >
        </Route>
        <Route
          path="/:lang/:country/:region/:marina/:dateFrom/:dateTo/:boatLength/:boatWidth"
          element={<Marina />}
          exact={false}
        >
        </Route>
        <Route
          path="/:lang/:country/:region/:marina/:area/year/:boatLength/:boatWidth"
          element={<Marina />}
          exact={false}
        >
        </Route>
        <Route
          path="/:lang/:country/:region/:marina/:area/:dateFrom/:dateTo/:boatLength/:boatWidth"
          element={<Marina />}
          exact={false}
        >
        </Route>
        <Route
          path="/:lang/:country/:region"
          element={<Region />}
          exact={false}
        >
        </Route>
        <Route
          path="/:lang/:country/:region/year/:boatLength/:boatWidth"
          element={<Region />}
          exact={false}
        >
        </Route>
        <Route
          path="/:lang/:country/:region/:dateFrom/:dateTo/:boatLength/:boatWidth"
          element={<Region />}
          exact={false}
        >
        </Route>
        <Route path='/:lang/404' element={<My404Page/>} />
        <Route path='*'  element={<Navigate replace to={"/" + i18n.language + "/404"} />} />
      </Routes>
      <Container>
        {props.children}
      </Container>
    </div>
  );
}