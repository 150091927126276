const Urls = {
	'regions': {
		'home'				: /^\/(?<lang>[a-z]{2,3})\/(?<countryName>[a-z-]+)\/(?<regionUrlName>[a-z-]+)-(?<regionId>\d+)/g,
		'withDateAndSize'	: /^\/(?<lang>[a-z]{2,3})\/(?<countryName>[a-z-]+)\/(?<regionUrlName>[a-z-]+)-(?<regionId>\d+)\/(?<dateFrom>[0-9]{4}-[0-9]{2}-[0-9]{2})\/(?<dateTo>[0-9]{4}-[0-9]{2}-[0-9]{2})\/(?<boatLength>[0-9\.]+)\/(?<boatWidth>[0-9\.]+)$/g,
		'fullYearAndSize'	: /^\/(?<lang>[a-z]{2,3})\/(?<countryName>[a-z-]+)\/(?<regionUrlName>[a-z-]+)-(?<regionId>\d+)\/year\/(?<boatLength>[0-9\.]+)\/(?<boatWidth>[0-9\.]+)$/g,
	},
	'marinas' : {
		'home' 					: /^\/(?<lang>[a-z]{2,3})\/(?<countryName>[a-z-]+)\/(?<regionName>[a-z-]+)\/(?<marinaUrlName>[a-z-]+)-(?<marinaId>\d+)/g,
		'homeWithDateAndSize' 	: /^\/(?<lang>[a-z]{2,3})\/(?<countryName>[a-z-]+)\/(?<regionName>[a-z-]+)\/(?<marinaUrlName>[a-z-]+)-(?<marinaId>\d+)\/(?<dateFrom>[0-9]{4}-[0-9]{2}-[0-9]{2})\/(?<dateTo>[0-9]{4}-[0-9]{2}-[0-9]{2})\/(?<boatLength>[0-9\.]+)\/(?<boatWidth>[0-9\.]+)$/g,
		'homeFullYearAndSize'	: /^\/(?<lang>[a-z]{2,3})\/(?<countryName>[a-z-]+)\/(?<regionName>[a-z-]+)\/(?<marinaUrlName>[a-z-]+)-(?<marinaId>\d+)\/year\/(?<boatLength>[0-9\.]+)\/(?<boatWidth>[0-9\.]+)$/g,
		'homeArea'				: /^\/(?<lang>[a-z]{2,3})\/(?<countryName>[a-z-]+)\/(?<regionName>[a-z-]+)\/(?<marinaUrlName>[a-z-]+)-(?<marinaId>\d+)\/(?<areaName>[a-z-]+)-(?<areaId>\d+)/g,
		'withDateAndSize' 		: /^\/(?<lang>[a-z]{2,3})\/(?<countryName>[a-z-]+)\/(?<regionName>[a-z-]+)\/(?<marinaUrlName>[a-z-]+)\/(?<areaName>[a-z-]+)-(?<areaId>\d+)\/(?<dateFrom>[0-9]{4}-[0-9]{2}-[0-9]{2})\/(?<dateTo>[0-9]{4}-[0-9]{2}-[0-9]{2})\/(?<boatLength>[0-9\.]+)\/(?<boatWidth>[0-9\.]+)$/g,
		'fullYearAndSize'		: /^\/(?<lang>[a-z]{2,3})\/(?<countryName>[a-z-]+)\/(?<regionName>[a-z-]+)\/(?<marinaUrlName>[a-z-]+)\/(?<areaName>[a-z-]+)-(?<areaId>\d+)\/year\/(?<boatLength>[0-9\.]+)\/(?<boatWidth>[0-9\.]+)$/g,
	},
	'home': /^\/(?<lang>[a-z]{2,3})$/g,
	'lang': /^\/(?<lang>[a-z]{2,3})\//g,
	getRegionMainUrl(path) {
		this.regions.home.lastIndex = 0;
		let results = this.regions.home.exec(path);
		return results[0];
	},
	getMarinaMainUrl(path) {
		this.marinas.home.lastIndex = 0;
		let results = this.marinas.home.exec(path);
		return results[0];
	},
	isHome(path) {
		this.home.lastIndex = 0;
		let results = this.home.exec(path);
		return results !== null;
	},
	isMarinaURL(path) {
		let marinasKeys = Object.keys(this.marinas);
		for (let i = 0; i < marinasKeys.length; i++) {
			this.marinas[marinasKeys[i]].lastIndex = 0;
			let result = this.marinas[marinasKeys[i]].exec(path);
			if (result) {
				return true;
			}
		}
		return false;
	},
	isRegionURL(path) {
		let regionsKeys = Object.keys(this.regions);
		for (let i = 0; i < regionsKeys.length; i++) {
			this.regions[regionsKeys[i]].lastIndex = 0;
			let result = this.regions[regionsKeys[i]].exec(path);
			if (result) {
				return true;
			}
		}
		return false;
	}
};

export default Urls;
