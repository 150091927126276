import React from "react";
import Grid from '@mui/material/Grid'; // Grid version 1
import './ButtonWithIcon.css';
import Button from '@mui/material/Button';

export function ButtonWithIcon(props) {
	const icon = props.renderIcon();
	const disabled = props.disabled !== undefined ? props.disabled : false;
	const component = props.component !== undefined ? props.component : "label";
	if (props.component !== undefined) {
		return (
			<>
			<Button
				variant="contained"
				className={'buttonWithIcon ' + props.className}
				component={component}
				onClick={props.onClick}
				disabled={disabled}
				to={props.to}
				>
				<Grid
					container
					justify="flex-end"
				>
			        <Grid item xs={12} sm={2} className="buttonIcon" align="left">
		            	{icon}
					</Grid>
			        <Grid item xs={0} sm={10} align="right" className="buttonTitle">
						{props.label}
					</Grid>
				</Grid>
			</Button>
			</>
		);
	}
	return (
		<>
		<Button
			variant="contained"
			className={'buttonWithIcon ' + props.className}
			component={component}
			onClick={props.onClick}
			disabled={disabled}
			>
			<Grid
				container
				justify="flex-end"
				alignItems="center"
			>
		        <Grid item xs={12} sm={2} className="buttonIcon" align="left">
	            	{icon}
				</Grid>
		        <Grid item xs={0} sm={10} align="right" className="buttonTitle">
					{props.label}
				</Grid>
			</Grid>
		</Button>
		</>
	);
}