import React, { Component } from 'react';

import { MarkerF, InfoWindow } from '@react-google-maps/api';

const pinColor = "#1E5891";
const pinColorSelected = "#FF6854";
const pinSVGHole = "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z";
const pinSVGFilled = "M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z";
const labelOriginFilled =  {x: 12, y: 9};
let markerIcon = {  // https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerLabel
  path: pinSVGHole,
  anchor: {x:12, y:17},
  fillOpacity: 1,
  fillColor: pinColor,
  strokeWeight: 2,
  strokeColor: "white",
  scale: 2,
  labelOrigin: labelOriginFilled,
};

export class LocationMarker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapMarker: null,
      animated: false
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.highlightedIdx !== this.props.highlightedIdx) {
      let newMarkerIcon = JSON.parse(JSON.stringify(markerIcon));
      if (this.props.highlightedIdx == this.props.location.idmarinas) {
        newMarkerIcon.fillColor = pinColorSelected;
        this.state.mapMarker.setIcon(newMarkerIcon);
        this.state.mapMarker.setAnimation(window.google.maps.Animation.BOUNCE);
        this.state.mapMarker.setZIndex(9999);
        this.setState({
          animated: true
        })
      } else if (this.state.animated) {
        newMarkerIcon.fillColor = pinColor;
        this.state.mapMarker.setIcon(markerIcon);
        this.state.mapMarker.setAnimation(window.google.maps.Animation.wA);
        this.state.mapMarker.setZIndex(undefined);
        this.setState({
          animated: false
        })
      }
    }
  }

  onLoad(mapMarker) {
    this.setState({
      mapMarker: mapMarker
    });
  }

  onMarkerClick(e) {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  }
  onMarkerMouseOver(e) {
    if (this.props.onMouseOver) {
      this.props.onMouseOver(this.props.idx, this.state.mapMarker);
    }
  }
  onMarkerMouseOut(e) {
    if (this.props.onMouseOut) {
      this.props.onMouseOut(this.props.idx, this.state.mapMarker);
    }
  }
  render() {
    let localMarkerIcon = JSON.parse(JSON.stringify(markerIcon));
    if (this.props.isMain) {
      localMarkerIcon.fillColor = pinColorSelected;
    }
    if (this.props.zIndex) {
      return <MarkerF
            position={ this.props.location.coords }
            onLoad={this.onLoad.bind(this)}
            icon={localMarkerIcon}
            onClick={this.onMarkerClick.bind(this)}
            onMouseOver={this.onMarkerMouseOver.bind(this)}
            onMouseOut={this.onMarkerMouseOut.bind(this)}
            animation={window.google.maps.Animation.wA}
            zIndex={this.props.zIndex}
      />;
    }
    return <MarkerF
          position={ this.props.location.coords }
          onLoad={this.onLoad.bind(this)}
          icon={localMarkerIcon}
          onClick={this.onMarkerClick.bind(this)}
          onMouseOver={this.onMarkerMouseOver.bind(this)}
          onMouseOut={this.onMarkerMouseOut.bind(this)}
          animation={window.google.maps.Animation.wA}
    />;
  }
} 

export default LocationMarker;
