import React from "react";

const LocationIcon = props => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none"  {...props}>
  <path d="M15.5445 16.8281C19.0252 14.3349 19.7779 9.55721 17.2258 6.15689C14.6736 2.75658 9.78304 2.02123 6.30235 4.51444C2.82166 7.00766 2.06892 11.7853 4.62107 15.1856C7.17322 18.5859 12.0638 19.3213 15.5445 16.8281Z" stroke="#28292D" strokeMiterlimit="10"/>
  <path d="M12.9167 12.6205L7.00391 14.4879L8.91548 8.71168L14.8376 6.84424L12.9167 12.6205Z" stroke="#28292D" strokeMiterlimit="10"/>
  <path d="M11.9795 10.6706C11.9795 11.2382 11.5017 11.7051 10.9207 11.7051C10.3397 11.7051 9.86182 11.2382 9.86182 10.6706C9.86182 10.1031 10.3397 9.63623 10.9207 9.63623C11.5017 9.63623 11.9795 10.1031 11.9795 10.6706Z" fill="#28292D"/>
  </svg>
);

export default LocationIcon;
