import React from "react";
import "./NavMenu.css";
import "../styles.css";
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import LanguageIcon from '../icons/LanguageIcon';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Urls from '../commons/Urls';
import { useLocation } from 'react-router-dom';

export function NavMenu(props) {
  const { t, i18n } = useTranslation();
  const [anchorElLanguage, setAnchorElLanguage] = React.useState(null);
  const languages = Object.keys(i18n.store.data);
  let locationURL = useLocation();
  const handleOpenLanguageMenu = (event) => {
    setAnchorElLanguage(event.currentTarget);
  }
  const handleCloseLanguageMenu = (language) => {
    setAnchorElLanguage(null);
    let previousLanguage = i18n.language;
    i18n.changeLanguage(language);
    // Need to redirect to the language url.
    Urls.lang.lastIndex = 0;
    let results = Urls.lang.exec(locationURL.pathname);
    let newPathname = locationURL.pathname.replace('/' + previousLanguage, '/' + language);
    window.location.href = newPathname; 
  }
  return (
    <>
      <Box component="nav" className="nav">
        <AppBar position="static">
          <Toolbar>
            <div align="left">
              <Link to={"/" + i18n.language}>
                <img className="logo" src="/images/logo.svg"/>
              </Link>
            </div>
            <div style={{width: '100%', textAlign: 'right'}}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="primary"
                onClick={handleOpenLanguageMenu}
              >
                <LanguageIcon />
              </IconButton>
            </div>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElLanguage}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElLanguage)}
              onClose={handleCloseLanguageMenu}
            >
              {languages.map((language) => (
                <MenuItem key={language} onClick={() => handleCloseLanguageMenu(language)}>
                  <Typography textAlign="center">{t('language.' + language)}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
}
