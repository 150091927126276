import React, { useEffect } from 'react';
import {
  BrowserRouter
} from "react-router-dom";
import { Layout } from './components/Layout';
import {
  RecoilRoot
} from 'recoil';

export default function App() {
  return (
    <>
    <RecoilRoot>
        <BrowserRouter>
          <Layout>
          </Layout>
        </BrowserRouter>
    </RecoilRoot>
    </>
  );
}
