import React, { useState, useEffect, useRef } from "react";
import Collapse from '@mui/material/Collapse';
import Map from "../components/Map";
import Grid from '@mui/material/Grid'; // Grid version 1
import { Typography } from "@mui/material";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Slider from '@mui/material/Slider';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import StepContent from '@mui/material/StepContent';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import MarinaAreaTypeColors from '../commons/MarinaAreaTypeColors';
import Tooltip from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import './Marina.css';
import {MarinaCard} from '../components/MarinaCard';
import { SearchBar } from '../components/SearchBar';
import { Price } from '../components/Price';
import {ButtonWithIcon} from '../components/ButtonWithIcon';
import LocationWhiteIcon from '../icons/LocationWhiteIcon';
import ContactIcon from '../icons/ContactIcon';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import RequestIcon from '../icons/RequestIcon';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Urls from '../commons/Urls';
import { useLocation } from 'react-router-dom';
import {SearchFieldsNotHome} from '../components/SearchFieldsNotHome';
import Modal from '@mui/material/Modal';
import Dialog from '@mui/material/Dialog';
import {
  useRecoilValue
} from 'recoil';
import {
  selectedSearchState
} from "../store/search_store";

function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}

function priceRow(qty, unit) {
  return qty * unit;
}

function createRow(desc, qty, unit) {
  const price = priceRow(qty, unit);
  return { desc, qty, unit, price };
}

function subtotal(items) {
  return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
}

const rows = [
  createRow('Paperclips (Box)', 100, 1.15),
  createRow('Paper (Case)', 10, 45.99),
  createRow('Waste Basket', 2, 17.99),
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#F7FBFF',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const mapOptions = {
  disableDefaultUI: true,
  gestureHandling: "none",
  fullscreenControl: false,
  keyboardShortcuts: false,
  scrollwheel: false,
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function Marina(props) {
  const { t, i18n } = useTranslation();
  const [previousSelectedMarina, setPreviousSelectedMarina] = useState(null);
  const selectedSearch = useRecoilValue(selectedSearchState);
  const [loading, setLoading] = useState(true);
  const [spots, setSpots] = useState([]);
  const [areas, setAreas] = useState([]);
  const [loadingAreas, setLoadingAreas] = useState(true);
  const [idMarinasAreasSelected, setIdMarinasAreasSelected] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);
  const [polygonsGMaps, setPolygonsGMaps] = useState([]);
  const [priceInfos, setPriceInfos] = useState(null);
  const [nearestMarinas, setNearestMarinas] = useState([]);
  const [priceInfosNearest, setPriceInfosNearest] = useState([]);
  const [periodPrices, setPeriodPrices] = useState([]);
  const [afterDateFromClick, setAfterDateFromClick] = useState(false);
  const [highlightedIdx, setHighlightedIdx] = useState(null);
  const [phoneModalOpen, setPhoneModalOpen] = useState(false);
  const priceRef = useRef();
  const [isVisible, _setIsVisible] = useState(true);
  const [openMakeRequest, setOpenMakeRequest] = React.useState(false);
  let locationURL = useLocation();

  const isVisibleRef = React.useRef(isVisible);
  const setIsVisible = value => {
    isVisibleRef.current = value;
    _setIsVisible(value);
  };

  useEffect(
    () => {
      if (selectedSearch.selectedMarina) {
        // Need to be sure it's the real URL, if not, redirect 301.
        /*let currentMainUrl = Urls.getMarinaMainUrl(locationURL.pathname);
        if (currentMainUrl !== "/" + i18n.language + selectedMarina.url) {
          window.location.href = locationURL.pathname.replace(currentMainUrl, "/" + i18n.language + selectedMarina.url);
          return;
        }*/
        if (JSON.stringify(previousSelectedMarina) !== JSON.stringify(selectedSearch.selectedMarina)) {
          setLoading(true);
          setPreviousSelectedMarina(selectedSearch.selectedMarina);
          loadMarinaInfo(selectedSearch.selectedMarina.idmarinas);
        } else {
          //setLoading(false);
        }
      } else {
      }
    },
    [selectedSearch]
  );

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);
  const scrollHandler = () => {
    if (window.pageYOffset <= priceRef.current.offsetTop
      && window.pageYOffset + window.innerHeight >= priceRef.current.offsetTop) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }      
  }
  const loadMarinaInfo = (idMarinas) => {
    let headers = {
      mode: 'cors',
    };

    fetch (process.env.REACT_APP_API_ALL_MAIN_URL_DOMAIN + '/marinas/' + idMarinas, {mode: 'cors'})
    .then(res => res.json())
    .then(
      (result) => {
      setSpots(result['spots']);
      },
      (error) => {
      }
    );
    setLoadingAreas(true);
    setAreas([]);
    setIdMarinasAreasSelected(null);
    setSelectedArea(null);
    fetch(process.env.REACT_APP_API_ALL_MAIN_URL_DOMAIN + '/marinas/' + idMarinas + '/areas', headers).then((response) => {
        if (response.status >= 400 && response.status < 600) {
          throw new Error("Bad response from server");
        }
        return response.json();
    }).then((areas) => {
      let localAreas = areas['areas'];
      localAreas.sort(function compare(a, b) {
        if (a.type === b.type) {
          return a['name'].localeCompare(b['name']);        
        }
        if (a.type === 'platform') {
          if (b.type === 'pontoon') {
            return b.beachable ? -1 : 1;
          }
          return -1;
        }
        if (a.type === 'pontoon') {
          if (b.type === 'platform') {
            return b.beachable ? -1 : 1;
          }
          return -1;
        }
        return a['name'].localeCompare(b['name']);
      });

      setLoadingAreas(false);
      setAreas(localAreas);

      let areaFound = false;
      for (let i = 0; i < localAreas.length; i++) {
        if (locationURL.pathname.search(localAreas[i].url) !== -1) {
          setIdMarinasAreasSelected(localAreas[i]['idMarinasAreas']);
          setSelectedArea(localAreas[i]);
          areaFound = true;
        }
      }
      if (!areaFound) {
        setIdMarinasAreasSelected(localAreas.length !== 0 ? localAreas[0]['idMarinasAreas'] : null);
        setSelectedArea(localAreas.length !== 0 ? localAreas[0] : null);        
      }
    }).catch((error) => {
      // Your error is here!
      console.log(error)
    });
    setNearestMarinas([]);
    fetch(process.env.REACT_APP_API_ALL_MAIN_URL_DOMAIN + '/marinas/' + idMarinas + '/nearest', headers).then((response) => {
        if (response.status >= 400 && response.status < 600) {
          throw new Error("Bad response from server");
        }
        return response.json();
    }).then((marinas) => {
      setNearestMarinas(marinas);
    }).catch((error) => {
      // Your error is here!
      console.log(error)
    });
  };

  const computeRegionUrl = (region) => {
    let url = '/' + i18n.language + region.url;
    if (selectedSearch.selectedFullYear) {
      url += '/year';
    } else {
      url += '/' + selectedSearch.selectedDateFrom.format('YYYY-MM-DD') + '/' + selectedSearch.selectedDateTo.format('YYYY-MM-DD');
    }
    url += '/' + selectedSearch.selectedBoatLength;
    url += '/' + selectedSearch.selectedBoatWidth;
    return url;
  }

  useEffect(() => {
    setPriceInfos(null);
    setPriceInfosNearest([]);
    setPeriodPrices([]);
    if (idMarinasAreasSelected
      && selectedSearch.selectedMarina
      && selectedSearch.selectedMarina.idmarinas === selectedArea.marina.idmarinas) {
      computePrice();
      computePriceNearest();
      computeAreaPeriodPrices();
    } else {
      setLoading(false);
    }
  }, [idMarinasAreasSelected, selectedSearch]);

  const computePrice = () => {
    let headers = {
        mode: 'cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'boatLength': parseFloat(selectedSearch.selectedBoatLength),
          'boatWidth': parseFloat(selectedSearch.selectedBoatWidth),
          'fullYear': selectedSearch.selectedFullYear,
          'dateFrom': selectedSearch.selectedDateFrom.format('YYYY-MM-DD'),
          'dateTo': selectedSearch.selectedDateTo.format('YYYY-MM-DD'),
        }),
    };
    fetch(process.env.REACT_APP_API_ALL_MAIN_URL_DOMAIN + '/areas/' + idMarinasAreasSelected + '/compute-price', headers).then((response) => {
        if (response.status >= 400 && response.status < 600) {
          throw new Error("Bad response from server");
        }
        return response.json();
    }).then((price) => {
      setPriceInfos(price);
    }).catch((error) => {
      setPriceInfos(null);
    });
  } 
  const computePriceNearest = () => {
    let headers = {
        mode: 'cors',
        method: 'POST',
        cacheControl: 'no-cache',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'Origin': 'http://localhost:3000',
        },
        body: JSON.stringify({
          boatLength: parseFloat(selectedSearch.selectedBoatLength),
          boatWidth: parseFloat(selectedSearch.selectedBoatWidth),
          fullYear: selectedSearch.selectedFullYear,
          dateFrom: selectedSearch.selectedDateFrom.format('YYYY-MM-DD'),
          dateTo: selectedSearch.selectedDateTo.format('YYYY-MM-DD'),
        }),
    };
    fetch(process.env.REACT_APP_API_ALL_MAIN_URL_DOMAIN + '/areas/' + idMarinasAreasSelected + '/compute-price/nearest', headers).then((response) => {
        if (response.status >= 400 && response.status < 600) {
          setPriceInfosNearest([]);
          throw new Error("Bad response from server");
        }
        return response.json();
    }).then((prices) => {
      prices.sort(function compare(a, b) {
        return a['price'] > b['price'] ? 1 : -1;
      });
      setPriceInfosNearest(prices);
    }).catch((error) => {
      setPriceInfosNearest([]);
    }).finally(() => {
      setLoading(false);
    });
  }

  const computeAreaPeriodPrices = () => {
    let headers = {
        mode: 'cors',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
    };
    let url = process.env.REACT_APP_API_ALL_MAIN_URL_DOMAIN + '/areas/' + idMarinasAreasSelected + '/period-prices';
    if (selectedSearch.selectedFullYear) {
      let today = dayjs();
      url += '/' + today.format('YYYY-MM-DD');
    } else {
      url += '/' + selectedSearch.selectedDateFrom.format('YYYY-MM-DD');
    }
    url += '/' + selectedSearch.selectedBoatLength;
    url += '/' + selectedSearch.selectedBoatWidth;

    fetch(url, headers).then((response) => {
        if (response.status >= 400 && response.status < 600) {
          setPeriodPrices([]);
          throw new Error("Bad response from server");
        }
        return response.json();
    }).then((prices) => {
      setPeriodPrices(prices);
    }).catch((error) => {
    });

  }
  useEffect(() => {
    if (mapInstance) {
      //drawAreaPolygons();
    }
  }, [mapInstance]);

  useEffect(() => {
    if (mapInstance) {
      //drawAreaPolygons();      
    }
  }, [selectedArea]);

  const handleOnLoadMap = (mapInstance) => {
    setMapInstance(mapInstance);
  }

  const drawAreaPolygons = () => {
    let marinaAreasPolygons = formatDBPolygonsToCoords(selectedArea.marinaAreasPolygons);
    for (let i = 0; i < polygonsGMaps.length; i++) {
      polygonsGMaps[i].setMap(null);
    }
    let newPolygonsGMaps = [];
    for (let j = 0; j < marinaAreasPolygons.length; j++) {
      let polygons = drawPolygon(marinaAreasPolygons[j], selectedArea);
      newPolygonsGMaps.push(polygons);
    }
    setPolygonsGMaps(newPolygonsGMaps);
  }

  const formatDBPolygonsToCoords = (marinaAreasPolygons) => {
    let polygons = [];
    for (let i = 0; i <  marinaAreasPolygons.length; i++) {
      let latLngList = marinaAreasPolygons[i]['polygon']['polygonsLatLng'];
      let coordsList = [];
      for (let j = 0; j < latLngList.length; j++) {
        coordsList.push({
          lat: latLngList[j]['latitude'],
          lng: latLngList[j]['longitude']
        });
      }
      polygons.push(coordsList);
    }
    return polygons;
  }

  const drawPolygon = (polygonsCoords, area) => {
    let polygonsGMaps = new window.google.maps.Polygon({
        paths: polygonsCoords,
        strokeColor: "#00FF00",
        strokeOpacity: 0.8,
        strokeWeight: 1,
        fillColor: computeAreaColor(area),
        fillOpacity: 0.7,
        editable: false,
        draggable: false,
        rotateControlOptions: false,
    });
    polygonsGMaps.setMap(mapInstance);
    return polygonsGMaps;
  }
  const computeAreaColor = (area) => {
    return MarinaAreaTypeColors.default[area.type][area.beachable ? 'yes' : 'no'];
  }

  const handleMouseEnter = (index) => {
    setHighlightedIdx(priceInfosNearest[index].area.marina.idmarinas);
  }

  const handleMouseLeave = (index) => {
    setHighlightedIdx(null);
  }
  const handleMakeRequest = () => {
    setOpenMakeRequest(true);
  }
  const handleMakeRequestClose = () => {
    setOpenMakeRequest(false);
  }

  const computeAreaLink = (area) => {
    let url = '/' + i18n.language + area.url;
    if (selectedSearch.selectedFullYear) {
      url += '/year';
    } else {
      url += '/' + selectedSearch.selectedDateFrom.format('YYYY-MM-DD') + '/' + selectedSearch.selectedDateTo.format('YYYY-MM-DD');
    }
    url += '/' + selectedSearch.selectedBoatLength;
    url += '/' + selectedSearch.selectedBoatWidth;
    return url;
  }
  const handleClickChangeArea = (area) => {
    setSelectedArea(area);
    setIdMarinasAreasSelected(area.idMarinasAreas);
  }

  const handleAddModalOpen = () => {
    setPhoneModalOpen(!phoneModalOpen);
  }

  const renderLocation = (location) => {
    let gmapsCenter = typeof location.gmaps !== 'undefined' ? location.gmaps : location.coords;
    let imgUrl = '/images/marina-placeholder.png';
    if (location.image) {
      imgUrl = process.env.REACT_APP_IMAGES_URL_DOMAIN + (window.innerWidth > 600 ? location.image.pathDesktop : location.image.pathDesktop);
    }
    let marinaImg = "url(\"" + imgUrl + "\")";
    return (
	    <>
        <Dialog onClose={handleMakeRequestClose} open={openMakeRequest} className="dialogMakeRequest">
          <Typography variant="h5">Coming soon</Typography>
        </Dialog>
        <div className="content">
	      <Grid container spacing={2}>
          <Grid item xs={0} sm={1}>
          </Grid>
          <Grid item xs={12} sm={10} className="marinaTopInfo">
            {location.region && (
              <>
              <div className="breadcrumb">
              Home > {location.region.country.name} > <b><Link to={computeRegionUrl(location.region)} style={{textDecoration: 'none', color: 'rgb(30, 88, 145)'}}>{location.region.name}</Link></b>
              </div>
              </>
            )}
            <div className="marinaImgMobile" style={{backgroundImage: marinaImg}}/>
            <Grid container className="marinaTopInfoContent">
              <Grid item xs={12} md={7} lg={5} className="marinaTopInfoContact">
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <div style={{height: '150px', paddingTop: '20px'}}>
                      <Typography variant="h5" className="marinaTitle"><Link to={'/' + i18n.language + location.url}  style={{textDecoration: 'none', color: 'rgb(30, 88, 145)'}}>{location.name}</Link></Typography>
                      <div className="marinaLocation" className="marinaLocation">{location.city} - {location.region.country.name} - <b><Link to={computeRegionUrl(location.region)} style={{textDecoration: 'none', color: 'rgb(30, 88, 145)'}}>{location.region.name}</Link></b></div>
                      <Grid container spacing={2} sx={{marginBottom: '10px', paddingLeft: '10px'}}>
                      {
                        location.address && (
                        <Grid item xs={4} sm={3} md={2} lg={4} align="left">
                          <ButtonWithIcon className="itineraryButton" label={t('itinerary')} renderIcon={() => <LocationWhiteIcon />} onClick={() => window.open("https://www.google.fr/maps/place/" + encodeURI(location.address))} />
                        </Grid>
                        )
                      }
                      {
                        location.phone && (
                        <>
                        <Modal
                          open={phoneModalOpen}
                          onClose={handleAddModalOpen}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={style} className="modalPhone">
                            <Grid container spacing={2} sx={{marginBottom: '10px', paddingLeft: '10px'}}>
                              <Grid item xs={8} align="left">
                                <Typography variant='h6'>{location.phone}</Typography>
                              </Grid>
                              <Grid item xs={4} align="left">
                                <a href={'tel:'+location.phone}><ButtonWithIcon className="contactButton" label={t('call')} renderIcon={() => <ContactIcon />}/></a>
                              </Grid>
                            </Grid>
                          </Box>
                        </Modal>
                        <Grid item xs={4} sm={3} md={2} lg={4} align="left">
                          <ButtonWithIcon className="contactButton" label={t('contact')} renderIcon={() => <ContactIcon />} onClick={handleAddModalOpen}/>
                        </Grid>
                        </>
                        )
                      }
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
                <div className="marinaImg" style={{backgroundImage: marinaImg}}/>
              </Grid>
              <Grid item xs={12} md={5} lg={3} className="areasSelectionBlockContainer" align="center">
                {!loadingAreas && (
                  <>
                  {areas.length !== 0 && selectedArea !== null && (
                  <Grid container className="areasSelectionBlock">
                    <Grid item xs={12}>
                      <div className="areasTitleNumber">{t('areas_found', {number: areas.length})}</div>
                      <Grid container style={{padding: '10px'}}>
                      {areas.map((area, index) => (
                        <Grid item xs={6} className={selectedArea.idMarinasAreas === area.idMarinasAreas ? 'areaTitleSelected' : 'areaTitle'}>
                        <Tooltip title={area.name}>
                        {selectedArea.idMarinasAreas !== area.idMarinasAreas
                        ? <Link to={computeAreaLink(area)} onClick={() => handleClickChangeArea(area)} style={{textDecoration: 'none', color: 'black'}}><div>{area.name}</div></Link>
                        : <div>{area.name}</div>
                        }
                        </Tooltip>
                        </Grid>
                      ))}
                      </Grid>
                    </Grid>
                  </Grid>
                  )}
                  </>
                )}
                <div ref={priceRef}>
                {priceInfos && (
                <>
                <Grid container sx={{width: '90%'}} spacing={1}>
                  <Grid item xs={6} md={12} align="center">
                    <div className="yourLocation">
                      <Typography variant="h6">{t('your_spot')}</Typography>
                      { selectedSearch.selectedFullYear && (
                        t('full_year')
                      )
                      }
                      { !selectedSearch.selectedFullYear && (
                        t('date_selected_from_to', {from: selectedSearch.selectedDateFrom.format('YYYY-MM-DD'), to: selectedSearch.selectedDateTo.format('YYYY-MM-DD')})
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={6} md={12} align="center">
                    <Grid container sx={{width: '90%'}} spacing={1}>
                      <Grid item xs={3} align="center">
                      </Grid>
                      <Grid item xs={6} align="center">
                        <Price currency="€" price={priceInfos ? Math.round(ccyFormat(priceInfos['price'])) : 'N/A'}/>
                        <div className="priceDetailsInfo">*{t('price_calculated_year', {year:priceInfos['year']['year']})}</div>
                      </Grid>
                      <Grid item xs={3} align="center">
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} align="center">
                    <Button
                      variant="contained"
                      className="buttonWithIcon sendRequestButton"
                      onClick={handleMakeRequest}
                      >
                        {t('send_request')}
                    </Button>
                  </Grid>
                </Grid>
                <br/>
                </>
                )}
                </div>
                {!priceInfos && areas.length !== 0 && (
                  <div className="yourLocation" style={{textAlign:'left', padding:'10px'}}>
                  <Typography variant="h6">{t('no_price_for_this_area')}</Typography>
                  </div>
                )}
                {areas.length === 0 && (
                  <div className="yourLocation" style={{textAlign:'left', padding:'10px'}}>
                  {t('why_no_price_explanation')}
                  </div>
                )}
                {Object.keys(periodPrices).length !== 0 && (
                <Grid container className="spotsSelectionBlock">
                  <Grid item xs={1}>
                  </Grid>
                  <Grid item xs={10}>
                    <div className="spotsTitleFrom" align="right">{t('price_from')}</div>
                    <Grid container spacing={1}>
                    {Object.keys(periodPrices).map((period, index) => (
                      <>
                      <Grid item xs={6} align="left" className="pricePeriodTitle">{t(period)}</Grid>
                      <Grid item xs={6} align="right" className="pricePeriod">{Math.round(periodPrices[period].price)}€</Grid>
                      </>
                    ))}
                    </Grid>
                  </Grid>
                  <Grid item xs={1}>
                  </Grid>
                </Grid>
                ) }
              </Grid>
              <Grid item xs={0} lg={4}>
                <Map
                  id="mapNearest"
                  locations={nearestMarinas}
                  mainLocation={selectedSearch.selectedMarina}
                  zoom={9}
                  center={gmapsCenter}
                  mapOptions={mapOptions}
                  highlightedIdx={highlightedIdx}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={0} sm={1}>
          </Grid>
          <Grid item xs={0} sm={1}>
          </Grid>
          <Grid item xs={12} sm={10} style={{paddingTop: '50px'}}>
            <Grid container>
            <Grid item xs={12} className="otherMarinaTitle">
          {priceInfosNearest.length !== 0 && (
            <>
            {t('other_marinas_near')}
            </>
          )}
            </Grid>
            </Grid>
            <Grid container>
          {priceInfosNearest.map((info, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} >
              <MarinaCard
                marina={info.area.marina}
                area={info.area}
                price={info.price}
                priceInfos={priceInfos}
                distance={info.distance}
                index={index}
                mouseEnter={handleMouseEnter}
                mouseLeave={handleMouseLeave}
              />
            </Grid>
          ))}
            </Grid>
          </Grid>
          <Grid item xs={0} sm={1}>
          </Grid>

          <Grid item xs={0} sm={1}>
          </Grid>
          <Grid item xs={12} sm={10} sx={{padding: '8px'}}>
          {priceInfos && typeof priceInfos['priceDetails'] !== 'undefined' && (
            <>
              <div className="priceDetailsTitle">{t('price_details')}</div>
              <div className="priceDetailsInfo">*{t('price_calculated_year', {year:priceInfos['year']['year']})}
              </div>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" colSpan={5}>
                        {t('details')}
                      </TableCell>
                      <TableCell align="center" colSpan={3}>{t('price')}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t('dateFrom')}</TableCell>
                      <TableCell>{t('dateTo')}</TableCell>
                      <TableCell>{t('season')}</TableCell>
                      <TableCell>{t('period')}</TableCell>
                      <TableCell align="right">{t('price')}</TableCell>
                      <TableCell align="right">{t('unit')}</TableCell>
                      <TableCell align="right">{t('sum')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {priceInfos['priceDetails'].map((row) => (
                      <TableRow key={row.desc}>
                        <TableCell>{dayjs(row.dateFrom).format('YYYY-MM-DD')}</TableCell>
                        <TableCell>{dayjs(row.dateTo).format('YYYY-MM-DD')}</TableCell>
                        <TableCell>{row.season.name}</TableCell>
                        <TableCell>{t(row.period)}</TableCell>
                        <TableCell align="right">{row.periodPrice}</TableCell>
                        <TableCell align="right">{row.repeat}</TableCell>
                        <TableCell align="right">{ccyFormat(row.price)}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell rowSpan={3} />
                      <TableCell colSpan={5} align="right"><b>{t('total')}</b></TableCell>
                      <TableCell align="right"><b>{ccyFormat(priceInfos['price'])}</b></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
          </Grid>
          <Grid item xs={0} sm={1}>
          </Grid>
	      </Grid>
        </div>
	    </>
    );
  }
  const renderLoading = () => {
    return (
      <>
      <p><em>Loading...</em></p>
      <div ref={priceRef}></div>
      </>
    );
  }

  let contents = loading || !selectedSearch.selectedMarina
    ? renderLoading()
    : renderLocation(selectedSearch.selectedMarina);

  return (
    <div>
      {selectedSearch.selectedMarina && !loading && (
      <div className="priceSlideUp" style={{display: isVisible ? 'none' : 'block'}}>
        <Collapse in={!isVisible}>
          <Typography variant="h5" className="marinaTitle"><Link to={'/' + i18n.language + selectedSearch.selectedMarina.url}  style={{textDecoration: 'none', color: 'rgb(30, 88, 145)'}}>{selectedSearch.selectedMarina.name}</Link></Typography>
          <Grid container spacing={2}>
            <Grid item xs={4}>
            </Grid>
            <Grid item xs={4}>
              <Price currency="€" price={priceInfos ? Math.round(ccyFormat(priceInfos['price'])) : 'N/A'}/>
            </Grid>
            <Grid item xs={4}>
            </Grid>
          </Grid>
        </Collapse>
      </div>
      )}
      <div className="searchContent">
        <SearchBar renderSearchFields={() => <SearchFieldsNotHome />}/>
      </div>
      {contents}
    </div>
  );
}
