import React from "react";

const CalendarIcon = props => (
<svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.1459 2.55615H3.64164C2.22881 2.55615 1.0835 3.67502 1.0835 5.05522V15.317C1.0835 16.6972 2.22881 17.816 3.64164 17.816H14.1459C15.5588 17.816 16.7041 16.6972 16.7041 15.317V5.05522C16.7041 3.67502 15.5588 2.55615 14.1459 2.55615Z" stroke="#28292D" strokeMiterlimit="10"/>
<path d="M5.375 4.25886V1" stroke="#28292D" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12.4126 4.25886V1" stroke="#28292D" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M6.27477 6.97754H5.03786C4.67042 6.97754 4.37256 7.26853 4.37256 7.62748V8.83582C4.37256 9.19478 4.67042 9.48576 5.03786 9.48576H6.27477C6.6422 9.48576 6.94007 9.19478 6.94007 8.83582V7.62748C6.94007 7.26853 6.6422 6.97754 6.27477 6.97754Z" fill="#28292D"/>
<path d="M12.6278 6.97754H11.3909C11.0235 6.97754 10.7256 7.26853 10.7256 7.62748V8.83582C10.7256 9.19478 11.0235 9.48576 11.3909 9.48576H12.6278C12.9952 9.48576 13.2931 9.19478 13.2931 8.83582V7.62748C13.2931 7.26853 12.9952 6.97754 12.6278 6.97754Z" fill="#28292D"/>
<path d="M6.27477 11.811H5.03786C4.67042 11.811 4.37256 12.102 4.37256 12.461V13.6693C4.37256 14.0283 4.67042 14.3193 5.03786 14.3193H6.27477C6.6422 14.3193 6.94007 14.0283 6.94007 13.6693V12.461C6.94007 12.102 6.6422 11.811 6.27477 11.811Z" fill="#28292D"/>
</svg>
);

export default CalendarIcon;
