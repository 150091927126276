import React from "react";

const LocationWhiteIcon = props => (
<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.4374 15.6296C17.6702 13.3139 18.3693 8.87651 15.999 5.71837C13.6286 2.56022 9.08629 1.87724 5.8535 4.19289C2.6207 6.50854 1.92158 10.9459 4.29196 14.1041C6.66235 17.2622 11.2046 17.9452 14.4374 15.6296Z" stroke="white" strokeMiterlimit="10"/>
<path d="M11.9968 11.7215L6.50513 13.456L8.28056 8.09113L13.7809 6.35669L11.9968 11.7215Z" stroke="white" strokeMiterlimit="10"/>
<path d="M11.1266 9.91069C11.1266 10.4378 10.6827 10.8714 10.1431 10.8714C9.60353 10.8714 9.15967 10.4378 9.15967 9.91069C9.15967 9.38356 9.60353 8.94995 10.1431 8.94995C10.6827 8.94995 11.1266 9.38356 11.1266 9.91069Z" fill="#46B9CC"/>
</svg>
);

export default LocationWhiteIcon;
