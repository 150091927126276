import React from "react";
import './Price.css';
import Grid from '@mui/material/Grid'; // Grid version 1

export function Price(props) {
	let heatColor = <></>
	if (props.heatColor) {
		heatColor = (
			<>
				<div className="heatColor" style={{backgroundColor: props.heatColor }}/>
			</>
		)
	}

	return (
		<>
    <Grid container>
      <Grid item xs={12} align="center">
				<div className="price">{props.price}{props.currency}</div>
				{heatColor}
			</Grid>
		</Grid>
		</>
	);
}