import { atom } from "recoil";

const regionQueryState = atom({
  key: "regionQueryState",
  default: {
    region: null,
  }
});

const regionMarinasState = atom({
  key: "regionMarinas",
  default: [],
});

const regionMarinasFilteredState = atom({
  key: "regionMarinasFiltered",
  default: [],
});

const regionMarinasPricesState = atom({
  key: "regionMarinasPrices",
  default: [],
});

export {
  regionMarinasState,
  regionQueryState,
  regionMarinasFilteredState,
  regionMarinasPricesState,
};
