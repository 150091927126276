import React, { useState, useEffect } from 'react';
import LocationMarker from './LocationMarker';
import './Map.css';
import { useTranslation } from 'react-i18next';

import { GoogleMap, LoadScript, MarkerClusterer, useJsApiLoader, InfoWindow } from '@react-google-maps/api';

export default function Map(props) {
  const { t } = useTranslation();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GMAP_KEY // ,
    // ...otherOptions
  });
  const [highlightWindow, setHighlightWindow] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);
  const [highlightedIdx, setHighlightedIdx] = useState(null);

  useEffect(() => {
      setHighlightedIdx(props.highlightedIdx);
  });

  const divStyle = {
    background: `white`,
    border: `1px solid #ccc`,
    padding: 15
  }
  const onMarkerClick = (e) => {

  }
  const onMarkerMouseOver = (idx, marker) => {
    if (!highlightWindow) {
      const infowindow = new window.google.maps.InfoWindow({
        content: computePriceContent(idx),
        ariaLabel: props.locations[idx].name,
      });
      infowindow.open({
          anchor: marker,
          mapInstance,
      });
      setHighlightWindow(infowindow);
    } else {
      highlightWindow.setAnchor(marker);
      highlightWindow.setContent(computePriceContent(idx));
    }
  }

  const computePriceContent = (idx) => {
    let price = "";
    if (typeof props.prices === "undefined"
      || props.prices.length === 0
      || idx === -1) {
      let location = props.mainLocation;
      if (idx !== -1) {
        location = props.locations[idx];
      }
      return "<h3>" + location.name + "</h3>";
    }
    let location = props.locations[idx];
    if (props.prices[location['idmarinas']] !== null) {
      if (props.prices[location['idmarinas']]['day']) {
        price = price + "<br/>A partir de <b>" + props.prices[location['idmarinas']]['day'] + " €</b> par jour";
      }
      if (props.prices[location['idmarinas']]['week']) {
        price = price + "<br/>A partir de <b>" + props.prices[location['idmarinas']]['week'] + " €</b> par semaine";
      }
      if (props.prices[location['idmarinas']]['month']) {
        price = price + "<br/>A partir de <b>" + props.prices[location['idmarinas']]['month'] + " €</b> par mois";
      }
      if (props.prices[location['idmarinas']]['year']) {
        price = price + "<br/>A partir de <b>" + props.prices[location['idmarinas']]['year'] + " €</b> par an";
      }
    }

    if (price === "") {
      price = t('no_price_info');
    }

    return "<h3>" + location.name + "</h3><div>" + price + "</div>";
  }

  const onMarkerMouseOut = (idx, marker) => {
    if (highlightWindow) {
      highlightWindow.close();
    }
  }

  const onLoad = React.useCallback(
    function onLoadMaps (instance) {
      setMapInstance(instance);
      let styles = [
        {
          featureType: "all",
          elementType: "labels",
          stylers: [
            { visibility: "off" }
          ]
        }
      ];
      instance.set('styles', styles);
      instance.setMapTypeId(props.zoom > 14 ? window.google.maps.MapTypeId.SATELLITE : window.google.maps.MapTypeId.ROADMAP);
      if (props.onLoad) {
        props.onLoad(instance);
      }
      if (window.google !== undefined) {
        if (props.onZoomChanged) {
          window.google.maps.event.addListener(instance, 'zoom_changed', props.onZoomChanged);
        }
        if (props.onCenterChanged) {
          window.google.maps.event.addListener(instance, 'center_changed', props.onCenterChanged);
        }
        if (props.onClick) {
          window.google.maps.event.addListener(instance, 'click', props.onClick);
        }
      }
    }
  , []);
  if (isLoaded) {
    let markers = (
        <>
          {props.locations.map((location, index) => (
              <LocationMarker isMain={false} idx={index} key={index} location={location} highlightedIdx={highlightedIdx} onClick={onMarkerClick} onMouseOver={onMarkerMouseOver} onMouseOut={onMarkerMouseOut}/>
            ))
          }
          {props.mainLocation !== undefined && (
              <LocationMarker idx={-1} isMain={true} location={props.mainLocation} highlightedIdx={highlightedIdx} onClick={onMarkerClick} onMouseOver={onMarkerMouseOver} onMouseOut={onMarkerMouseOut} zIndex={99999}/>
            )
          }
        </>
      );
    return (
        <GoogleMap center={props.center} zoom={props.zoom} options={props.mapOptions} id={props.id} onLoad={onLoad}>
            {markers}
        </GoogleMap>
    );      
  } else {
    return (
      <div>Loading</div>
    );
  }
}