import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid'; // Grid version 1
import Box from '@mui/material/Box';
import './SearchBar.css';
import Button from '@mui/material/Button';
import SearchIcon from '../icons/SearchIcon';
import dayjs from 'dayjs';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Urls from '../commons/Urls';
import {ButtonWithIcon} from '../components/ButtonWithIcon';

import {
  useRecoilValue
} from 'recoil';
import {
	localSelectedRegionState,
	localSelectedMarinaState,
	localSelectedBoatLengthState,
	localSelectedBoatWidthState,
	localSelectedDateFromState,
	localSelectedDateToState,
	localSelectedFullYearState
} from "../store/local_search_store";

export function SearchBar(props) {
  const { t, i18n } = useTranslation();
  const selectedRegion = useRecoilValue(localSelectedRegionState);
  const selectedMarina = useRecoilValue(localSelectedMarinaState);
  const selectedDateFrom = useRecoilValue(localSelectedDateFromState);
  const selectedDateTo = useRecoilValue(localSelectedDateToState);
  const selectedFullYear = useRecoilValue(localSelectedFullYearState);
	const selectedBoatLength = useRecoilValue(localSelectedBoatLengthState);
	const selectedBoatWidth = useRecoilValue(localSelectedBoatWidthState);
  const [searchButtonDisabled, setSearchButtonDisabled] = React.useState(true); 
  let locationURL = useLocation();

	useEffect(() => {
		computeDisabledSearchButton();
	}, [selectedRegion, selectedMarina, selectedBoatLength, selectedBoatWidth, selectedFullYear, selectedDateFrom, selectedDateTo]);

  const computeDisabledSearchButton = () => {
  	setSearchButtonDisabled(
  		!selectedBoatWidth
  		|| !selectedBoatLength
  		|| (!selectedMarina && !selectedRegion)
  		|| !(selectedFullYear
  			|| (selectedDateTo && selectedDateFrom))  		
			|| (!Urls.isHome(locationURL.pathname) && locationURL.pathname === computeUrl())
  	);
  }
  const computeUrl = () => {
  	let url = "/" + i18n.language;
  	if (searchButtonDisabled) {
  		return url;
  	}

  	if (selectedMarina) {
	    url += selectedMarina.url;
  	}
  	if (selectedRegion) {
			url += selectedRegion.url;
		}
		if (selectedFullYear) {
			url += '/year';
		} else {
			url += '/' + selectedDateFrom.format('YYYY-MM-DD') + '/' + selectedDateTo.format('YYYY-MM-DD');
		}
		url += '/' + selectedBoatLength + '/' + selectedBoatWidth;
		return url;
  }

	const searchFields = props.renderSearchFields();
	const fieldsMobileStyle = {
		xs: Urls.isHome(locationURL.pathname) ? 12 : 10,
	}
	const searchMobileStyle = {
		xs: Urls.isHome(locationURL.pathname) ? 12 : 2,
	}
	return (
		<>
	    <Box height="10vh" mr={4} className="searchBarDesktop">
	      <Grid
	      	container
	        style={{height: "100%",paddingTop: '12px'}}
	        justify="flex-end"
        	alignItems="center"
        	spacing={2}
	      >
	        <Grid item xs={3}>
	        </Grid>
	        <Grid item xs={6} align="center">
            	{searchFields}
	        </Grid>
	        <Grid item xs={2} align="left">
            <ButtonWithIcon component={Link} to={computeUrl()} className="searchButton" label={t('search')} renderIcon={() => <SearchIcon />} disabled={searchButtonDisabled}/>
	        </Grid>
	        <Grid item xs={1}>
	        </Grid>
	      </Grid>
    	</Box>
	    <Box mr={4} className="searchBarMobile">
	      <Grid
	      	container
	        style={{height: "100%"}}
	        justify="flex-end"
        	alignItems="center"
        	spacing={2}
	      >
	        <Grid item align="center" {...fieldsMobileStyle}>
	        {searchFields}
	        </Grid>
	        <Grid item align="center" {...searchMobileStyle}>
            <ButtonWithIcon component={Link} to={computeUrl()} className="searchButton" label={t('search')} renderIcon={() => <SearchIcon />} disabled={searchButtonDisabled}/>
	        </Grid>
	        <Grid item xs={1}>
	        </Grid>
	      </Grid>
    	</Box>
		</>
	);
}