import React from "react";
import "./MarinaCard.css";
import { Link } from "react-router-dom";
import Grid from '@mui/material/Grid'; // Grid version 1
import {Price} from './Price';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import {
  useRecoilState,
  useRecoilValue
} from 'recoil';
import {
  selectedMarinaState,
  selectedRegionState,
  selectedDateFromState,
  selectedDateToState,
  selectedFullYearState,
  selectedBoatLengthState,
  selectedBoatWidthState,
  selectedSearchState
} from "../store/search_store";

export function MarinaCard(props) {
  const { t, i18n } = useTranslation();
  const [selectedSearch, setSelectedSearch] = useRecoilState(selectedSearchState);
  const computePurcent = () => {
    if (!props.priceInfos) {
      return 0;
    }
    let purcent = Math.round(((props.price - props.priceInfos['price']) / props.priceInfos['price']) * 100);
    return purcent > 0 ? "+" + purcent : purcent;
  }
  const computeUrl = () => {
    let marina = props.marina;
    let url = '/' + i18n.language + marina.url;
    let area = props.area;
    if (area) {
      url = '/' + i18n.language + area.url;
    }
    if (selectedSearch.selectedFullYear) {
      url += '/year';
    } else {
      url += '/' + selectedSearch.selectedDateFrom.format('YYYY-MM-DD') + '/' + selectedSearch.selectedDateTo.format('YYYY-MM-DD');
    }
    url += '/' + selectedSearch.selectedBoatLength;
    url += '/' + selectedSearch.selectedBoatWidth;
    return url;
  }
  const getDuplicateSearchInfo = () => {
    let initSelectedSearch = JSON.parse(JSON.stringify(selectedSearch));
    initSelectedSearch.selectedDateFrom = dayjs(initSelectedSearch.selectedDateFrom);
    initSelectedSearch.selectedDateTo = dayjs(initSelectedSearch.selectedDateTo);
    return initSelectedSearch;
  }
  const handleOnClick = () => {
    let initSelectedSearch = getDuplicateSearchInfo();
    initSelectedSearch.selectedMarina = props.marina;
    setSelectedSearch(initSelectedSearch);
  }
  const handleMouseEnter= () => {
    if (props.mouseEnter
      &&  typeof props.index !== 'undefined') {
      props.mouseEnter(props.index);
    }
  }
  const handleMouseLeave= () => {
    if (props.mouseLeave
      && typeof props.index !== 'undefined') {
      props.mouseLeave(props.index);
    }
  }
  let purcent = parseInt(computePurcent());
  let priceColor = "#99C21C";
  if (purcent == 0) {
    priceColor = "#FFFFFFFF"; // transparent
  } else if (purcent > 0) {
    priceColor = "red";
  }
  if (props.priceColor) {
    priceColor = props.priceColor;
  }
  //process.env.REACT_APP_IMAGES_URL_DOMAIN
  return (
    <>
      <Grid item xs={12} sm={6} md={6} lg={4} xl={3} align="left" className="card">
        <Link to={computeUrl()} style={{textDecoration: 'none'}} onClick={handleOnClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          {props.distance && (
            <div>
              <Tooltip title="Imagine you're a bird :)">
                <div className="cardDistance">{Math.round(props.distance)} {t('knot_short')}</div>
              </Tooltip>
            </div>
          )}
          <div className="cardInfo">
            {props.isSponso && (
              <Tooltip title="Sponsorized">
                <div className="cardStar"><img src='./images/marina-star.png'/></div>
              </Tooltip>
            )}
            <div className="cardTitleCity">
              <div className="cardTitle">{props.marina.name}</div>
              <div className="cardLocalisation">{props.marina.city} - {props.marina.region.name} - {props.marina.region.country.name}</div>
            </div>
            <div className="cardImgContainer">
              <img className="cardImg" loading="lazy" src={props.marina.image ? process.env.REACT_APP_IMAGES_URL_DOMAIN + props.marina.image.pathDesktop : '/images/marina-placeholder.png'} alt={props.marina.name}/>
            </div>
            <div className="cardPriceBlock" style={{height: '60px'}}>
              <Grid container>
                <Grid item xs={6} align="center">
                  {purcent !== 0 && (
                    <>
                    <div className="cardPurcent" style={{backgroundColor: purcent < 0 ? "#99C21C" : "red" }}>
                    {purcent}%
                    </div>
                    </>
                  )
                  }
                </Grid>
                <Grid item xs={6} align="center" style={{textAlign: 'center'}}>
                {props.priceLoading && (
                  <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                  </Box>                      
                )}
                {props.price && !props.priceLoading && (
                  <>
                    <div className="cardPrice">
                      <Price price={Math.round(props.price)} heatColor={priceColor}currency="€"/>
                    </div>
                  </>
                )}
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="cardInfoMobile">
            {props.isSponso && (
              <Tooltip title="Sponsorized">
                <div className="cardStar"><img src='./images/marina-star.png'/></div>
              </Tooltip>
            )}
            <Grid container style={{height: '100%'}}>
              <Grid item xs={4}>
                <div className="cardImgContainer">
                  <img className="cardImg" loading="lazy" src={props.marina.image ? process.env.REACT_APP_IMAGES_URL_DOMAIN + props.marina.image.pathMobile : '/images/marina-placeholder.png'} alt={props.marina.name}/>
                </div>
              </Grid>
              <Grid item xs={8}>
                <div className="cardTitleCity">
                  <div className="cardTitle">{props.marina.name}</div>
                  <div className="cardLocalisation">{props.marina.city} - {props.marina.region.name} - {props.marina.region.country.name}</div>
                </div>
                <div className="cardPriceBlock" style={{height: '60px'}}>
                  <Grid container>
                    <Grid item xs={6} align="center">
                      {purcent !== 0 && (
                        <>
                        <div className="cardPurcent" style={{backgroundColor: purcent < 0 ? "#99C21C" : "red" }}>
                        {purcent}%
                        </div>
                        </>
                      )
                      }
                    </Grid>
                    <Grid item xs={6} align="center" style={{textAlign: 'center'}}>
                    {props.priceLoading && (
                      <Box sx={{ display: 'flex' }}>
                        <CircularProgress />
                      </Box>                      
                    )}
                    {props.price && !props.priceLoading && (
                      <>
                        <div className="cardPrice">
                          <Price price={Math.round(props.price)} heatColor={priceColor} currency="€"/>
                        </div>
                      </>
                    )}
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
        </Link>
      </Grid>
    </>
  );
}
