import React from "react";

const ContactIcon = props => (
<svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.4443 1.9787V11.2862H0.5V1.9787C0.5 1.16207 1.16226 0.5 1.97223 0.5H8.97207C9.78279 0.5 10.4443 1.15829 10.4443 1.9787Z" stroke="white"/>
<path d="M0.5 14.7706V11.4443H10.4443V14.7706C10.4443 15.6572 9.76538 16.3374 8.97126 16.3374H1.97304C1.18198 16.3374 0.5 15.6604 0.5 14.7706Z" stroke="white"/>
<circle cx="5.47196" cy="13.8909" r="0.920935" stroke="white"/>
<circle cx="5.47196" cy="2.94657" r="0.920935" stroke="white"/>
</svg>
);

export default ContactIcon;
