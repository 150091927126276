import React from "react";

const BoatSizeIcon = props => (
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_120_337)">
<path d="M0.837891 8H15.1541" stroke="#28292D" strokeMiterlimit="10"/>
<path d="M3.41416 11.1737L3.9418 10.6072L1.14064 8.00003L3.9418 5.39286L3.41416 4.82642L0 8.00003L3.41416 11.1737Z" fill="#28292D"/>
<path d="M12.5857 11.1737L12.0581 10.6072L14.8593 8.00003L12.0581 5.39286L12.5857 4.82642L15.9999 8.00003L12.5857 11.1737Z" fill="#28292D"/>
<path d="M8 0.838135V15.1543" stroke="#28292D" strokeMiterlimit="10"/>
<path d="M4.82617 3.41416L5.39261 3.9418L7.99979 1.14064L10.5992 3.9418L11.1734 3.41416L7.99979 0L4.82617 3.41416Z" fill="#28292D"/>
<path d="M4.82617 12.5857L5.39261 12.0581L7.99979 14.8593L10.5992 12.0581L11.1734 12.5857L7.99979 15.9999L4.82617 12.5857Z" fill="#28292D"/>
</g>
<defs>
<clipPath id="clip0_120_337">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>
);

export default BoatSizeIcon;
