import React from "react";
import './My404Page.css';
import { Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';

export default function My404Page(props) {
  const { t } = useTranslation();
	return (
		<div className="my404Content">
			<div className="titleError">
				<Typography variant="h2" style={{    fontWeight: 700}}>{t('error404')}</Typography>
			</div>
		</div>
	);
}
