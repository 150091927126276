import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid'; // Grid version 1
import './SearchFieldsNotHome.css';
import LocationIcon from '../icons/LocationIcon';
import CalendarIcon from '../icons/CalendarIcon';
import BoatSizeIcon from '../icons/BoatSizeIcon';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import {
  usePopupState,
  bindFocus,
  bindPopover,
} from 'material-ui-popup-state/hooks'
import { Typography } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import Slider from '@mui/material/Slider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Urls from '../commons/Urls';
import {
  useRecoilState,
  useRecoilValue
} from 'recoil';
import {
	selectedSearchState,
	locationState,
	searchMarinasState,
	searchRegionsState

} from "../store/search_store";
import {
	localSelectedRegionState,
	localSelectedMarinaState,
	localSelectedBoatLengthState,
	localSelectedBoatWidthState,
	localSelectedDateFromState,
	localSelectedDateToState,
	localSelectedFullYearState,
	localLocationState
} from "../store/local_search_store";

const marksLength = [
  { value: 5, label: '5m',},
  { value: 10, label: '10m', },
  { value: 15, label: '15m', },
  { value: 20, label: '20m', },
  { value: 25, label: '25m', },
];

const marksWidth = [
  { value: 2, label: '2m',},
  { value: 3, label: '3m',},
  { value: 4, label: '4m',},
  { value: 5, label: '5m',},
];

function valuetext(value) {
  return `${value}m`;
}

export function SearchFieldsNotHome(props) {
  const { t } = useTranslation();
  const popupStateLocation = usePopupState({
    variant: 'popover',
    popupId: 'popoverLocation',
  });
	const [stateLocationPrevious, setStateLocationPrevious] = useState(false);
  const popupStateDate = usePopupState({
    variant: 'popover',
    popupId: 'popoverDate',
  });
  const popupStateSize = usePopupState({
    variant: 'popover',
    popupId: 'popoverSize',
  });
	const [marinas, setMarinas] = useRecoilState(searchMarinasState);
	const [regions, setRegions] = useRecoilState(searchRegionsState);
	const [dateToday, setDateToday] = React.useState(dayjs());

  const [location, setLocation] = useRecoilState(locationState);
  const [selectedSearch, setSelectedSearch] = useRecoilState(selectedSearchState);

  /** Local change **/
  const [localLocation, setLocalLocation] = useRecoilState(localLocationState);
  const [localSelectedBoatLength, setLocalSelectedBoatLength] = useRecoilState(localSelectedBoatLengthState);
  const [localSelectedBoatWidth, setLocalSelectedBoatWidth] = useRecoilState(localSelectedBoatWidthState);
  const [localSelectedDateFrom, setLocalSelectedDateFrom] = useRecoilState(localSelectedDateFromState);
  const [localSelectedDateTo, setLocalSelectedDateTo] = useRecoilState(localSelectedDateToState);
  const [localSelectedRegion, setLocalSelectedRegion] = useRecoilState(localSelectedRegionState);
  const [localSelectedMarina, setLocalSelectedMarina] = useRecoilState(localSelectedMarinaState);
  const [localFullYear, setLocalFullYear] = useRecoilState(localSelectedFullYearState);

  const [dateToMaxValue, setDateToMaxValue] = React.useState(selectedSearch.selectedDateFrom.add(4, 'month'));

  let locationURL = useLocation();

  const computeInitialLocationValue = () => {
  	if (selectedSearch.selectedRegion
  		&& Urls.isRegionURL(locationURL.pathname)) {
  		setLocation(selectedSearch.selectedRegion.name);
  		return;
  	}
  	if (selectedSearch.selectedMarina
  		&& Urls.isMarinaURL(locationURL.pathname)) {
  		setLocation(selectedSearch.selectedMarina.name);
  		return;
  	}
  	return;
  }

  useEffect(() => {
  		computeInitialLocationValue();
	  },
	  [selectedSearch.selectedMarina, selectedSearch.selectedRegion]
  );

  useEffect(
    () => {
      let pathname = decodeURI(locationURL.pathname);
      let results = testRegex(Urls.regions.withDateAndSize, pathname);
      if (results) {
      	loadRegion(results.groups.regionId, results.groups.dateFrom, results.groups.dateTo, parseFloat(results.groups.boatLength), parseFloat(results.groups.boatWidth), false);
      	return;
      }
      results = testRegex(Urls.regions.fullYearAndSize, pathname);
      if (results) {
      	loadRegion(results.groups.regionId, null, null, parseFloat(results.groups.boatLength), parseFloat(results.groups.boatWidth), true);
      	return;
      }
      results = testRegex(Urls.regions.home, pathname);
      if (results) {
      	loadRegion(results.groups.regionId, null, null, 7, 2.5, true);
      	return;
      }
      results = testRegex(Urls.marinas.homeWithDateAndSize, pathname);
      if (results) {
      	loadMarina(results.groups.marinaId, results.groups.dateFrom, results.groups.dateTo, parseFloat(results.groups.boatLength), parseFloat(results.groups.boatWidth), false);
      	return;
      }
      results = testRegex(Urls.marinas.homeFullYearAndSize, pathname);
      if (results) {
      	loadMarina(results.groups.marinaId, null, null, parseFloat(results.groups.boatLength), parseFloat(results.groups.boatWidth), true);
      	return;
      }
      results = testRegex(Urls.marinas.withDateAndSize, pathname);
      if (results) {
      	loadMarinaArea(results.groups.areaId, results.groups.dateFrom, results.groups.dateTo, parseFloat(results.groups.boatLength), parseFloat(results.groups.boatWidth), false);
      	return;
      }
      results = testRegex(Urls.marinas.fullYearAndSize, pathname);
      if (results) {
      	loadMarinaArea(results.groups.areaId, null, null, parseFloat(results.groups.boatLength), parseFloat(results.groups.boatWidth), true);
      	return;
      }
      results = testRegex(Urls.marinas.homeArea, pathname);
      if (results) {
      	loadMarina(results.groups.marinaId, null, null, 7, 2.5, false);
      	return;
      }
      results = testRegex(Urls.marinas.home, pathname);
      if (results) {
      	loadMarina(results.groups.marinaId, null, null, 7, 2.5, false);
      	return;
      }
    },
    [locationURL]
  );

  const testRegex = (regex, pathname) => {
  	regex.lastIndex = 0;
  	return regex.exec(pathname);
  }

  const loadRegion = (regionId, dateFrom, dateTo, boatLength, boatWidth, fullYear) => {
  	let initSelectedSearch = initDatesAndBoatSize(dateFrom, dateTo, fullYear, boatLength, boatWidth);
		fetch (process.env.REACT_APP_API_ALL_MAIN_URL_DOMAIN + '/regions/' + regionId, {mode: 'cors'})
		.then(res => res.json())
		.then(
			(result) => {
				initSelectedSearch.selectedRegion = result;
		  	if (JSON.stringify(JSON.parse(JSON.stringify(initSelectedSearch))) !== JSON.stringify(JSON.parse(JSON.stringify(selectedSearch)))) {
					setSelectedSearch(initSelectedSearch);
				}
				setLocalSelectedRegion(result);
				setLocalSelectedMarina(null);
	      initLocalSearch(initSelectedSearch);
			},
			(error) => {
			}
		);
  }
  const loadMarina = (marinaId, dateFrom, dateTo, boatLength, boatWidth, fullYear) => {
  	let initSelectedSearch = initDatesAndBoatSize(dateFrom, dateTo, fullYear, boatLength, boatWidth);
    fetch (process.env.REACT_APP_API_ALL_MAIN_URL_DOMAIN + '/marinas/' + marinaId, {mode: 'cors'})
    .then(res => res.json())
    .then(
      (returnedMarina) => {
				initSelectedSearch.selectedMarina = returnedMarina;
		  	if (JSON.stringify(JSON.parse(JSON.stringify(initSelectedSearch))) !== JSON.stringify(JSON.parse(JSON.stringify(selectedSearch)))) {
	      	setSelectedSearch(initSelectedSearch);
	      }
      	setLocalSelectedMarina(returnedMarina);
				setLocalSelectedRegion(null);
	      initLocalSearch(initSelectedSearch);
      },
      (error) => {
      }
    );
  }

  const loadMarinaArea = (areaId, dateFrom, dateTo, boatLength, boatWidth, fullYear) => {
  	let initSelectedSearch = initDatesAndBoatSize(dateFrom, dateTo, fullYear, boatLength, boatWidth);
    fetch (process.env.REACT_APP_API_ALL_MAIN_URL_DOMAIN + '/areas/' + areaId, {mode: 'cors'})
    .then(res => res.json())
    .then(
      (areaMarina) => {
				initSelectedSearch.selectedMarina = areaMarina.marina;
		  	if (JSON.stringify(JSON.parse(JSON.stringify(initSelectedSearch))) !== JSON.stringify(JSON.parse(JSON.stringify(selectedSearch)))) {
    	  	setSelectedSearch(initSelectedSearch);
    	  }
	      setLocalSelectedMarina(areaMarina.marina);
				setLocalSelectedRegion(null);
	      initLocalSearch(initSelectedSearch);
      },
      (error) => {
      }
    );
  };
  const initDatesAndBoatSize = (dateFrom, dateTo, fullYear, boatLength, boatWidth) => {
  	let initSelectedSearch = getDuplicateSearchInfo();
  	if (dateFrom) {
  		let dayJSFrom = dayjs(dateFrom);
  		if (!selectedSearch.selectedDateFrom
  			|| dayJSFrom.format('YYYY-MM-DD') !== selectedSearch.selectedDateFrom.format('YYYY-MM-DD')) {
	    	initSelectedSearch.selectedDateFrom = dayjs(dateFrom);
  		}
  	}
  	if (dateTo) {
  		let dayJSTo = dayjs(dateTo);
  		if (!selectedSearch.selectedDateTo
  			|| dayJSTo.format('YYYY-MM-DD') !== selectedSearch.selectedDateTo.format('YYYY-MM-DD')) {
	    	initSelectedSearch.selectedDateTo = dayjs(dateTo);
	    }
  	}
  	initSelectedSearch.selectedBoatLength = boatLength;
  	initSelectedSearch.selectedBoatWidth = boatWidth;
  	initSelectedSearch.selectedFullYear = fullYear;
  	return initSelectedSearch;
  }

  const initLocalSearch = (initSelectedSearch) => {
  	setLocalSelectedDateFrom(dayjs(initSelectedSearch.selectedDateFrom));  			
  	setLocalSelectedDateTo(dayjs(initSelectedSearch.selectedDateTo));
  	setLocalSelectedBoatLength(initSelectedSearch.selectedBoatLength);
  	setLocalSelectedBoatWidth(initSelectedSearch.selectedBoatWidth);
  	setLocalFullYear(initSelectedSearch.selectedFullYear);  	
  }
  const getDuplicateSearchInfo = () => {
  	let initSelectedSearch = JSON.parse(JSON.stringify(selectedSearch));
  	initSelectedSearch.selectedDateFrom = dayjs(initSelectedSearch.selectedDateFrom);
  	initSelectedSearch.selectedDateTo = dayjs(initSelectedSearch.selectedDateTo);
  	return initSelectedSearch;
  }

  const handleFullYearChange = (event) => {
    setLocalFullYear(event.target.checked);
		if (event.target.checked) {
			popupStateDate.setOpen(false);
		}
  }

	const handleChangeLocation = (event) => {
		setLocalSelectedMarina(null);
		setLocalSelectedRegion(null);
		setLocation(event.target.value);
	}

	useEffect(() => {
		if (!location
			|| location.length < 3
			|| !popupStateLocation.isOpen) {
			return;
		}
    const delayDebounceFn = setTimeout(() => {
			fetch (process.env.REACT_APP_API_ALL_MAIN_URL_DOMAIN + '/marinas/search?'  + new URLSearchParams({
	    	name: location,
			}), {mode: 'cors'})
			.then(res => res.json())
			.then(
				(result) => {
					setMarinas(result);
				},
				(error) => {
				}
			);
			fetch (process.env.REACT_APP_API_ALL_MAIN_URL_DOMAIN + '/regions/search?'  + new URLSearchParams({
	    	name: location,
			}), {mode: 'cors'})
			.then(res => res.json())
			.then(
				(result) => {
					setRegions(result);
				},
				(error) => {
				}
			);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
	}, [location]);

	const removeAccents = (name) => {
		return name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
	}
	const printSearchResult = (name) => {
		var searchMask = removeAccents(location);
		var regEx = new RegExp(searchMask, "ig");
		var replaceMask = '<strong>' + location + '</strong>';

		var result = removeAccents(name).replace(regEx, replaceMask);
		return (
			<div dangerouslySetInnerHTML={{__html: result}} />
		);
	}
	const getRegionCountryCity = (marina) => {
		let name = marina.region.country.name + ' - ' + marina.region.name
		if (marina.city) {
			name = name + ' - ' + marina.city;
		}
		return name;
	}
	const handleMarinaSelect = (marina) => {
		setLocalSelectedMarina(marina);
		setLocalSelectedRegion(null);
		setLocation(marina.name);
		popupStateLocation.setOpen(false);
	}
	const handleRegionSelect = (region) => {
		setLocalSelectedMarina(null);
		setLocalSelectedRegion(region);
		setLocation(region.name);
		popupStateLocation.setOpen(false);
	}
  const handleDateFromChange = (newDate) => {
    setLocalSelectedDateFrom(newDate);
    setLocalSelectedDateTo(newDate.add(1, 'day'));
    setDateToMaxValue(newDate.add(4, 'month'));
  }

  const handleDateToChange = (newDate) => {
    setLocalSelectedDateTo(newDate);
		popupStateDate.setOpen(false);
  }
	const handleChangeLength = (event, value) => {
		setLocalSelectedBoatLength(value);
	}

	const handleChangeWidth = (event, value) => {
		setLocalSelectedBoatWidth(value);
	}
	const printSizeResult = () => {
		return localSelectedBoatLength + 'm x ' + localSelectedBoatWidth + 'm';
	}
	const printDateChoice = () => {
		if (localFullYear) {
			return t("full_year");
		}
		let content = localSelectedDateFrom.format('YYYY-MM-DD') + ' - ' + localSelectedDateTo.format('YYYY-MM-DD');
		if (window.innerWidth < 1201) {
			content = localSelectedDateFrom.format('YY/MM/DD') + ' - ' + localSelectedDateTo.format('YY/MM/DD');
		}
		return content;
	}

	useEffect(() => {
		if (popupStateLocation.isOpen
			&& stateLocationPrevious !== popupStateLocation.isOpen) {
			popupStateLocation.anchorEl.select();
		}
		setStateLocationPrevious(popupStateLocation.isOpen);
	}, [popupStateLocation]);

	return (
		<>
      <div className="sfnh-searchFieldsMain">
      <Grid container className="sfnh-searchFieldsMainGrid" >
        <Grid
        	item
        	xs={12}
        	md={4}
		      className="sfnh-searchFieldsElement sfnh-searchFieldLocation"
		      >
			      <TextField
			        id="location-input-id"
			        label={t("location")}
			        InputProps={{
			          startAdornment: (
			            <InputAdornment position="start" style={{color: 'black'}}>
			              <LocationIcon />
			            </InputAdornment>
			          ),
			        }}
			        variant="standard"
			        value={location}
			        placeholder={t('city_or_region')}
			        onChange={handleChangeLocation}
			        required={true}
							{...bindFocus(popupStateLocation)}
			      />
						<Popover
							{...bindPopover(popupStateLocation)}
							anchorOrigin={{
							  vertical: 'bottom',
							  horizontal: 'left',
							}}
							transformOrigin={{
							  vertical: 'top',
							  horizontal: 'left',
							}}
						>
							<Box className="locationsWidthPopOver">
				      <Grid container style={{paddingTop: '2vh'}} >
				        <Grid item xs={12} sm={6} align="left">
									<Typography variant="h6">{t("marinas")}</Typography>
							    {
							    	marinas.length === 0 && (
							    		<div>{t("no_result")}</div>
							    	)
							    }
							    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
					        {
					          marinas.map((marina, index) => (
					          <>
								      <ListItem key={index} alignItems="flex-start" className="sfnh-searchElement" onClick={() => handleMarinaSelect(marina)}>
								        <ListItemAvatar>
								          <Avatar alt={marina.name} src="/images/marina-placeholder.png" />
								        </ListItemAvatar>
								        <ListItemText
								          primary={printSearchResult(marina.name)}
								          secondary={
								            <React.Fragment>
								              <Typography
								                sx={{ display: 'inline' }}
								                component="span"
								                variant="body2"
								                color="text.primary"
								              >
								                {marina.region && (
								                	printSearchResult(getRegionCountryCity(marina))
								                )}
								              </Typography>
								            </React.Fragment>
								          }
								        />
								      </ListItem>
								      <Divider variant="inset" component="li" />
								    </>
					          ))
					        }
							    </List>
								</Grid>
				        <Grid item xs={12} sm={6} align="left" >
									<Typography variant="h6">{t("regions")}</Typography>
							    {
							    	regions.length === 0 && (
							    		<div>{t("no_result")}</div>
							    	)
							    }
							    	<List sx={{ width: '100%', bgcolor: 'background.paper' }}>
					        {
					          regions.map((region, index) => (
					          <>
								      <ListItem key={index} alignItems="flex-start" className="sfnh-searchElement" onClick={() => handleRegionSelect(region)}>
								        <ListItemAvatar>
								          <Avatar alt="Remy Sharp" src="/images/marina-placeholder.png" />
								        </ListItemAvatar>
								        <ListItemText
								          primary={printSearchResult(region.name)}
								          secondary={
								            <React.Fragment>
								              <Typography
								                sx={{ display: 'inline' }}
								                component="span"
								                variant="body2"
								                color="text.primary"
								              >
								                {region.country.name}
								              </Typography>
								            </React.Fragment>
								          }
								        />
								      </ListItem>
								      <Divider variant="inset" component="li" />
								    </>
					          ))
					        }
							   	</List>
								</Grid>
							</Grid>
							</Box>
						</Popover>
        </Grid>
        <Grid
        	item
        	xs={7}
        	md={5}
		      className="sfnh-searchFieldsElement sfnh-searchFieldDates"
		      >
		      <TextField
		        id="date-input-id"
		        label={t('Dates')}
		        InputProps={{
		          startAdornment: (
		            <InputAdornment position="start" style={{color: 'black'}}>
		              <CalendarIcon />
		            </InputAdornment>
		          ),
	            readOnly: true
		        }}
		        variant="standard"
		        value={printDateChoice()}
		        required={true}
						{...bindFocus(popupStateDate)}
		      />
						<Popover
							{...bindPopover(popupStateDate)}
							anchorOrigin={{
							  vertical: 'bottom',
							  horizontal: 'center',
							}}
							transformOrigin={{
							  vertical: 'top',
							  horizontal: 'center',
							}}
						>
							<Box className="datesPopOver">
				      <Grid container style={{paddingTop: '2vh'}} >
				      	<Grid item xs={12} align="center">
		              <FormControlLabel
		                control={
		                  <Switch name="Full year" onChange={handleFullYearChange} checked={localFullYear} />
		                }
		                label={t("full_year")}
		              />
				      	</Grid>
				        <Grid item xs={12} sm={6} align="left">
									<Typography variant="h6">{t("arrival")}</Typography>
		              <LocalizationProvider dateAdapter={AdapterDayjs}>
		                <CalendarPicker minDate={dateToday} date={localSelectedDateFrom} onChange={handleDateFromChange} disabled={localFullYear}/>
		              </LocalizationProvider>
								</Grid>
				        <Grid item xs={12} sm={6} align="left" >
									<Typography variant="h6">{t("departure")}</Typography>
				          <LocalizationProvider dateAdapter={AdapterDayjs}>
				            <CalendarPicker date={localSelectedDateTo} minDate={localSelectedDateFrom.add(1, 'day')} maxDate={dateToMaxValue} onChange={handleDateToChange} disabled={localFullYear}/>
				          </LocalizationProvider>
								</Grid>
							</Grid>
							</Box>
						</Popover>
        </Grid>
        <Grid
        	item
        	xs={5}
        	md={3}
		      className="sfnh-searchFieldsElement sfnh-searchFieldBoatSize"
        >
		      <TextField
		        id="boat-size-input-id"
		        label={t("boat_size")}
		        InputProps={{
		          startAdornment: (
		            <InputAdornment position="start" style={{color: 'black'}}>
		              <BoatSizeIcon />
		            </InputAdornment>
		          ),
	            readOnly: true,
		        }}
		        variant="standard"
		        value={printSizeResult()}
		        required={true}
		        style={{borderBottom: 'inherit'}}
						{...bindFocus(popupStateSize)}
		      />
						<Popover
							{...bindPopover(popupStateSize)}
							anchorOrigin={{
							  vertical: 'bottom',
							  horizontal: 'right',
							}}
							transformOrigin={{
							  vertical: 'top',
							  horizontal: 'right',
							}}
						>
							<Box className="boatSizePopOver">
								<Typography variant="h6">{t("length")}</Typography>
								<Slider
									aria-label="Always visible"
									defaultValue={localSelectedBoatLength}
									getAriaValueText={valuetext}
									step={0.5}
									marks={marksLength}
									min={5}
									max={25}
									sx={{ width: '95%'}}
									value={localSelectedBoatLength}
					        onChange={handleChangeLength}
								/>
								<Typography variant="h6">{t("width")}</Typography>
								<Slider
									aria-label="Always visible"
									defaultValue={localSelectedBoatWidth}
									getAriaValueText={valuetext}
									step={0.2}
									marks={marksWidth}
					        min={2}
					        max={5}
									sx={{ width: '95%'}}
									value={localSelectedBoatWidth}
					        onChange={handleChangeWidth}
								/>
							</Box>
						</Popover>
        </Grid>
      </Grid>
      </div>
		</>
	);
}