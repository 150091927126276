import React, { useEffect } from "react";
import './RealHome.css';
import { SearchBar } from '../components/SearchBar';
import { Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import Urls from '../commons/Urls';
import { useLocation } from 'react-router-dom';
import {SearchFields} from '../components/SearchFields';

export default function RealHome(props) {
  	const { t, i18n } = useTranslation();
	let locationURL = useLocation();
	useEffect(() => {
      	Urls.home.lastIndex = 0;
		let results = Urls.home.exec(decodeURI(locationURL.pathname));
		if (!results) {
			window.location.href = '/en';
			return;
		}
		let availabledLanguages = Object.keys(i18n.store.data);
		if (!availabledLanguages.includes(results.groups.lang)) {
			window.location.href = '/en';
			return;
		}
		if (i18n.language !== results.groups.lang) {
			i18n.changeLanguage(results.groups.lang);
		}
	}, [locationURL]);
	return (
	<div className="homeContent">
		<div className="titleSearch">
			<Typography variant="h2" className="homeTitle">{t('homeTitle')}</Typography>
			<SearchBar renderSearchFields={() => <SearchFields/>}/>
		</div>
	</div>
	);
}
