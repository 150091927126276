import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import Map from "../components/Map";
import Grid from '@mui/material/Grid'; // Grid version 1
import Paper from '@mui/material/Paper';
import { styled, alpha } from '@mui/material/styles';
import { Typography } from "@mui/material";
import './Region.css';
import { MarinaCard } from '../components/MarinaCard';
import SearchIcon from '@mui/icons-material/Search';
import Clear from '@mui/icons-material/Clear';
import InputBase from '@mui/material/InputBase';
import { SearchBar } from '../components/SearchBar';
import { useTranslation } from 'react-i18next';
import Urls from '../commons/Urls';
import {SearchFieldsNotHome} from '../components/SearchFieldsNotHome';
import {
  useRecoilState,
  useRecoilValue
} from 'recoil';
import {
	selectedSearchState
} from "../store/search_store";
import {
	regionQueryState,
	regionMarinasState,
	regionMarinasFilteredState,
	regionMarinasPricesState
} from "../store/region_store";

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#F7FBFF',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const mapOptions = {
  //gestureHandling: "none",
  keyboardShortcuts: false,
  scrollwheel: false,
  disableDefaultUI: true,
  fullscreenControl: false,
  zoomControl: true  
}

const colors = [
'#4ae671ff',
'#a1c052ff',
'#f89933ff',
'#eb5e2bff',
'#dd2323ff',
];

export default function Region(props) {
  const { t, i18n } = useTranslation();
  const selectedSearch = useRecoilValue(selectedSearchState);
  const [regionQuery, setRegionQuery] = useRecoilState(regionQueryState);
  const [regionMarinas, setRegionMarinas] = useRecoilState(regionMarinasState);
  const [regionMarinasFiltered, setRegionMarinasFiltered] = useRecoilState(regionMarinasFilteredState);
  const [regionMarinasPrices, setRegionMarinasPrices] = useRecoilState(regionMarinasPricesState);

  const [loading, setLoading] = useState(true);
  const [zoom, setZoom] = useState(window.innerWidth < 601 ? 7 : 8);
  const [center, setCenter] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);
  const [highlightedIdx, setHighlightedIdx] = useState(null);
  const [cheapestPrice, setCheapestPrice] = useState(null);
  const [highestPrice, setHighestPrice] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [loadingPrices, setLoadingPrices] = useState(false);
  const [loadingMarinas, setLoadingMarinas] = useState(false);
  let locationURL = useLocation();

  useEffect(
    () => {
    	if (!selectedSearch.selectedRegion) {
    		return;
    	}
    	if (JSON.stringify(regionQuery.region) !== JSON.stringify(selectedSearch.selectedRegion)) {
    		// Need to be sure it's the real URL, if not, redirect 301.
    		let currentMainUrl = Urls.getRegionMainUrl(locationURL.pathname);
    		if (currentMainUrl !== "/" + i18n.language + selectedSearch.selectedRegion.url) {
    			window.location.href = locationURL.pathname.replace(currentMainUrl, "/" + i18n.language + selectedSearch.selectedRegion.url);
    			return;
    		}
    		setRegionQuery({
    			region: selectedSearch.selectedRegion,
    			dateFrom: selectedSearch.selectedDateFrom,
    			dateTo: selectedSearch.selectedDateTo,
    			fullYear: selectedSearch.selectedFullYear,
    			boatLength: selectedSearch.selectedBoatLength,
    			boatWidth: selectedSearch.selectedBoatWidth,
    		});
      	loadMarinas(selectedSearch.selectedRegion.idregions);
	    	loadMarinasPrices(selectedSearch.selectedRegion);
    	} else if (regionQuery.dateFrom.format('YYYY-MM-DD') !== selectedSearch.selectedDateFrom.format('YYYY-MM-DD')
    		|| regionQuery.dateTo.format('YYYY-MM-DD') !== selectedSearch.selectedDateTo.format('YYYY-MM-DD')
    		|| regionQuery.fullYear !== selectedSearch.selectedFullYear
    		|| regionQuery.boatLength !== selectedSearch.selectedBoatLength
    		|| regionQuery.boatWidth !== selectedSearch.selectedBoatWidth) {
	    	loadMarinasPrices(selectedSearch.selectedRegion);
				setRegionQuery({
    			region: selectedSearch.selectedRegion,
    			dateFrom: selectedSearch.selectedDateFrom,
    			dateTo: selectedSearch.selectedDateTo,
    			fullYear: selectedSearch.selectedFullYear,
    			boatLength: selectedSearch.selectedBoatLength,
    			boatWidth: selectedSearch.selectedBoatWidth,
    		});
    	}
    },
    [locationURL, selectedSearch]
  );

  const loadMarinas = (regionId) => {
  	setLoadingMarinas(true);
		fetch (process.env.REACT_APP_API_ALL_MAIN_URL_DOMAIN + '/regions/' + regionId + '/marinas', {mode: 'cors'})
		.then(res => res.json())
		.then(
			(result) => {
				setRegionMarinas(result['marinas']);
				setLoadingMarinas(false);
			},
			(error) => {
			}
		);
  }
	const loadMarinasPrices = (region) => {
		let url = process.env.REACT_APP_API_ALL_MAIN_URL_DOMAIN + '/regions/' + region['idregions'] + '/marinas/prices';
		if (selectedSearch.selectedFullYear) {
			url += '/year';
		} else {
			url += '/' + selectedSearch.selectedDateFrom.format('YYYY-MM-DD') + '/' + selectedSearch.selectedDateTo.format('YYYY-MM-DD');
		}
		url += '/' + selectedSearch.selectedBoatLength + '/' + selectedSearch.selectedBoatWidth;
		setLoadingPrices(true);
		fetch (url, {mode: 'cors'})
		.then(res => res.json())
		.then(
			(result) => {
				setRegionMarinasPrices(result['prices']);
				setLoadingPrices(false);
			},
			(error) => {
			}
		);
	}

  const computePricesLimits = () => {
  	let pricePeriod = 'value';
		let cheapest = null;
		let highest = null;
		let pricesKeys = Object.keys(regionMarinasPrices);
		for (let i = 0; i < pricesKeys.length; i++) {
			if (regionMarinasPrices[pricesKeys[i]] === null
				|| regionMarinasPrices[pricesKeys[i]][pricePeriod] === null) {
				continue;
			}
			if (cheapest === null || cheapest > regionMarinasPrices[pricesKeys[i]][pricePeriod]) {
				cheapest = Math.round(regionMarinasPrices[pricesKeys[i]][pricePeriod]);
			}
			if (highest === null || highest < regionMarinasPrices[pricesKeys[i]][pricePeriod]) {
				highest = Math.round(regionMarinasPrices[pricesKeys[i]][pricePeriod]);
			}
		}
		return {
			highestPrice: highest,
			cheapestPrice: cheapest,
		}
  }

  const reorderLocations = (locations) => {
  	let pricePeriod = 'value';
  	let order = 'price-asc';
  	if (order === 'alphabetic') {
		  locations.sort(function compare(a, b) {
		  	return a['name'].localeCompare(b['name']);	  		
		  });
	  	return locations;
  	}

	  locations.sort(function compare(a, b) {
	  	let aPrice = regionMarinasPrices[a['idmarinas']] !== undefined ? regionMarinasPrices[a['idmarinas']] : null;
	  	let bPrice = regionMarinasPrices[b['idmarinas']] !== undefined ? regionMarinasPrices[b['idmarinas']] : null;
	  	if (aPrice === null
	  		&& bPrice === null) {
		  	return a['name'].localeCompare(b['name']);	  		
	  	}
	  	if (aPrice !== null
	  		&& aPrice[pricePeriod] !== null 
	  		&& (bPrice === null || bPrice[pricePeriod] === null)) {
	  		return -1;
	  	}
	  	if ((aPrice === null || aPrice[pricePeriod] === null)
	  		&& bPrice !== null
	  		&& bPrice[pricePeriod] !== null) {
	  		return 1;
	  	}
	  	if (aPrice !== null
	  		&& aPrice[pricePeriod] !== null 
	  		&& bPrice !== null
	  		&& bPrice[pricePeriod] !== null ) {
	  		if (aPrice[pricePeriod] === bPrice[pricePeriod]) {
			  	return a['name'].localeCompare(b['name']);
	  		}
	  		if (order === 'price-asc') {
		  		return aPrice[pricePeriod] > bPrice[pricePeriod] ? 1 : -1;
	  		} else {
		  		return aPrice[pricePeriod] > bPrice[pricePeriod] ? -1 : 1;
	  		}
	  	}
	  	return a['name'].localeCompare(b['name']);
	  });

	  return locations;
  }

  const handleMapLoad = (mapInstance) => {
  	setMapInstance(mapInstance);
  }

  const handleOnZoomChanged = () => {
  	setZoom(mapInstance.getZoom());
  }

  const getCenterMap = (locations) => {
		let baricentre = {
			lat: 0,
			lng: 0
		};
		for (let i = 0; i < locations.length; i++) {
			baricentre.lat += locations[i]['coords']['lat'];
			baricentre.lng += locations[i]['coords']['lng'];
		}
		if (locations.length) {
			baricentre.lat /= locations.length;
			baricentre.lng /= locations.length;
		} else {
			return { lat: 48.18440232931123, lng: -3.50456672654537513 };
		}
		return baricentre;
  }

	const computePriceColor = (price, cheapestPrice, highestPrice) => {
		if (!price
			|| !cheapestPrice
			|| !highestPrice) {
			return "#00000000";
		}
		let marinaPrice = price['value'];
		if (marinaPrice === null) {
			return "#00000000";			
		}
		let colorIdx = Math.round((marinaPrice - cheapestPrice) / (highestPrice - cheapestPrice) * 4);
		return colors[colorIdx];
	}

	const filterSearchLocations = (locations) => {
		if (!searchValue
			|| searchValue.length === 0) {
			return locations;
		}
		return locations.filter(location => location.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(searchValue.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) > -1 || (location.city !== null && location.city.toLowerCase().indexOf(searchValue.toLowerCase()) > -1));
	}
	const onSearchChange = (event) => {
		setSearchValue(event.target.value);
	}
	const handleClearSearch = () => {
		setSearchValue('');
	}

  useEffect(
    () => {
    	if (loadingPrices || loadingMarinas) {
    		return;
    	}
    	let locations = JSON.parse(JSON.stringify(regionMarinas));
			let filteredLocations = filterSearchLocations(locations);
			filteredLocations = reorderLocations(filteredLocations);
			setRegionMarinasFiltered(filteredLocations);
  		let pricesLimits = computePricesLimits();
			setHighestPrice(pricesLimits.highestPrice);
			setCheapestPrice(pricesLimits.cheapestPrice);
			setCenter(getCenterMap(regionMarinas));
    },
    [searchValue, regionMarinas, regionMarinasPrices, loadingPrices, loadingMarinas]
  );
  const handleMouseLeave = (index) => {
  	setHighlightedIdx(null);
  }
  const handleMouseEnter = (index) => {
  	setHighlightedIdx(regionMarinasFiltered[index].idmarinas);
  }
  return (
    <>
    <div className="searchContent">
      <SearchBar renderSearchFields={() => <SearchFieldsNotHome />}/>
    </div>
    <div className="content">
      <Grid container>
        <Grid item xs={12} md={8}>
					<Item>
			      <Grid container>
				      <Grid container>
				        <Grid item xs={6} align="left" style={{paddingLeft : '10px', color: '#1E5891'}}>
		        		{selectedSearch.selectedRegion && (
		        			<>
		        			<div className="breadcrumb">
		        			Home > {selectedSearch.selectedRegion.country.name} > <b>{selectedSearch.selectedRegion.name}</b>
		        			</div>
		        			<div className="marinaFoundNumber">
		        			{t('marina_found', {number : regionMarinas.length})}
		        			</div>
		        			</>
		        		)}
				       	</Grid>
				        <Grid item xs={0} md={0}>
				       	</Grid>
				        <Grid item xs={0} md={6} align="right" style={{paddingRight : '20px', color: '#4C4C4C'}} className="filterBlock">
				          <Search style={{display: 'inline-block'}}>
				            <SearchIconWrapper>
				              <SearchIcon />
				            </SearchIconWrapper>
				            <StyledInputBase
				              placeholder={t('filter')}
				              inputProps={{ 'aria-label': 'search' }}
				              onChange={onSearchChange}
				              value={searchValue}
				            />
				            <div className="clearSearch" onClick={handleClearSearch}>
				              <Clear/>
				            </div>
				          </Search>
				        	<div style={{width: '100px', display:'inline-block'}}>{t('order')}<span className="filterOrderSpan">></span></div>
				       	</Grid>
			       	</Grid>
		        </Grid>
			      <Grid container className="rightSumUpList">
								{
									regionMarinasFiltered.map((location, index) => (
					          <MarinaCard
					          	marina={location}
					          	area={regionMarinasPrices[location.idmarinas] && regionMarinasPrices[location.idmarinas] ? regionMarinasPrices[location.idmarinas]['area'] : null}
					          	price={regionMarinasPrices[location.idmarinas] && regionMarinasPrices[location.idmarinas]['value'] ? regionMarinasPrices[location.idmarinas]['value'] : null}
					          	priceColor={computePriceColor(regionMarinasPrices[location.idmarinas], cheapestPrice, highestPrice)}
					          	isSponso={false}
					          	index={index}
					          	key={index}
					          	mouseEnter={handleMouseEnter}
					          	mouseLeave={handleMouseLeave}
					          	priceLoading={loadingPrices || loadingMarinas}
					          />
								  ))
								}
								{
									regionMarinasFiltered.length === 0 && (
			        		<Grid item xs={12}>
										<Typography variant="h6">{t('no_result')}</Typography>
									</Grid>
									)
								}
						</Grid>
					</Item>
        </Grid>
        <Grid item xs={12} md={4} className="homeLeftMap">
          <Item>
          	<Map
          		id="mapHome"
          		locations={regionMarinas}
          		prices={regionMarinasPrices}
          		loading={loading}
          		zoom={zoom}
          		center={center}
          		mapOptions={mapOptions}
          		onZoomChanged={handleOnZoomChanged}
          		onLoad={handleMapLoad}
          		highlightedIdx={highlightedIdx}
          	/>
          </Item>
        </Grid>
      </Grid>
    </div>
    </>
  );
}
