import { atom } from "recoil";
import dayjs from 'dayjs';

const selectedSearchState = atom({
  key: "selectedSearch",
  default: {
    selectedMarina: null,
    selectedRegion: null,
    selectedFullYear: false,
    selectedDateFrom: dayjs(),
    selectedDateTo: dayjs().add(1, 'day'),
    selectedBoatLength: 10,
    selectedBoatWidth: 3,
  },
});

const selectedRegionState = atom({
  key: "selectedRegion",
  default: null,
});

const selectedMarinaState = atom({
  key: "selectedMarina",
  default: null,
});
 
const selectedFullYearState = atom({
  key: "selectedFullYear",
  default: false,
});

const selectedDateFromState = atom({
  key: "selectedDateFrom",
  default: dayjs(),
});

const selectedDateToState = atom({
  key: "selectedDateTo",
  default: dayjs().add(1, 'day'),
});

const selectedBoatLengthState = atom({
  key: "selectedBoatLength",
  default: 10,
});

const selectedBoatWidthState = atom({
  key: "selectedBoatWidth",
  default: 3,
});

const locationState = atom({
  key: "location",
  default: "",
});

const searchRegionsState = atom({
  key: "searchRegions",
  default: [],
});
const searchMarinasState = atom({
  key: "searchMarinas",
  default: [],
});

export {
  selectedRegionState,
  selectedMarinaState,
  selectedDateFromState,
  selectedDateToState,
  selectedBoatLengthState,
  selectedBoatWidthState,
  selectedFullYearState,
  locationState,
  searchRegionsState,
  searchMarinasState,
  selectedSearchState,
};
