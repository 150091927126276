import { atom } from "recoil";
import dayjs from 'dayjs';

const localSelectedRegionState = atom({
  key: "selectedLocalRegion",
  default: null,
});

const localSelectedMarinaState = atom({
  key: "selectedLocalMarina",
  default: null,
});

const localSelectedFullYearState = atom({
  key: "selectedLocalFullYear",
  default: false,
});

const localSelectedDateFromState = atom({
  key: "selectedLocalDateFrom",
  default: dayjs(),
});

const localSelectedDateToState = atom({
  key: "selectedLocalDateTo",
  default: dayjs().add(1, 'day'),
});

const localSelectedBoatLengthState = atom({
  key: "selectedLocalBoatLength",
  default: 10,
});

const localSelectedBoatWidthState = atom({
  key: "selectedLocalBoatWidth",
  default: 3,
});

const localLocationState = atom({
  key: "localLocation",
  default: "",
});

export {
  localSelectedRegionState,
  localSelectedMarinaState,
  localSelectedDateFromState,
  localSelectedDateToState,
  localSelectedBoatLengthState,
  localSelectedBoatWidthState,
  localSelectedFullYearState,
  localLocationState,
};
